import React, { Component } from "react";

//Import internal components
import Lab from "./lab";
import AppContext from "../../appContext";
import { Link } from "react-router-dom";

//Import external components

//Import CSS
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

//Import images
import botaoLab from "../img/botao_labs3.png";
import labsMenos from "../img/labsMenos.png";

class LabChooseAtacado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllLabs: [],
      labs: [],
      laboratorio: {},
      opacity: "",
      loading: false,
      onlyPreferidos: false,
      hasPreferidos: false,
      allPreferidos: false,
    };

    this.filterLabs = this.filterLabs.bind(this);
    this.showAllLabs = this.showAllLabs.bind(this);
    this.hideAllLabs = this.hideAllLabs.bind(this);
    this.acessaMinhaOtica = this.acessaMinhaOtica.bind(this);
    LabChooseAtacado.contextType = AppContext;
  }

  componentDidMount() {
    document.getElementById("main-body").style.pointerEvents = "none";
    document.getElementById("myNav").style.display = "block";
    this.setState({
      loading: true,
    });

    this.context.showLoading("");
    let labsa = [];
    let onlyPreferidos = false;
    let hasPreferidos = false;
    let allPreferidos = false;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpjs: this.props.otica,
        papel: this.props.papel_usuario,
        conta: this.props.conta_usuario,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_labs.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.items) {
          for (var i = 0; i < json.items.length; i++) {
            if (json.items[i].Atacado_c === true) {
              if (!onlyPreferidos) {
                onlyPreferidos = hasPreferidos = json.items[i].IsPreferido ? json.items[i].IsPreferido : false;
              }

              var pushObj = {
                Id: json.items[i].Id,
                nome: json.items[i].Nome_c,
                telefone: json.items[i].Telefone_c,
                site: json.items[i].Site_c,
                email: json.items[i].Email_c,
                cnpj: json.items[i].CNPJ_c,
                logo: json.items[i].URL_Logo_c,
                promo: json.items[i].Promocao_c,
                corteRemoto: json.items[i].RemoteEdge_c,
                detailOrder: json.items[i].DetalhesDoPedido_c,
                IsCor_AR: json.items[i].IsCor_AR_c,
                IsGrowmocao: json.items[i].Growmocao_c,
                IsPreferido: json.items[i].IsPreferido ? json.items[i].IsPreferido : false,
                PossuiProgramaFidelidadeProprio_c: json.items[i].PossuiProgramaFidelidadeProprio_c,
                link: "/atacado",
              };
              labsa.push(pushObj);
            }
          }
        }

        if (labsa.length === 0) {
          var empty = {
            nome: "Sua ótica ainda não tem cadastro ativo em nenhum laboratório habilitado no Conecta Atacado.",
            link: "/home",
            mensagemCadastro: true,
          };
          labsa.push(empty);
        }

        if (hasPreferidos) {
          if (
            labsa.length ===
            labsa.filter(item => {
              return item.IsPreferido;
            }).length
          ) {
            allPreferidos = true;
          }
        }

        document.getElementById("main-body").style.pointerEvents = "";
        document.getElementById("myNav").style.display = "none";
        this.setState(
          {
            loading: false,
            AllLabs: labsa,
            onlyPreferidos: onlyPreferidos,
            hasPreferidos: hasPreferidos,
            allPreferidos: allPreferidos,
          },
          this.filterLabs,
        );
      });
  }

  filterLabs() {
    var LabsAux = this.state.AllLabs.sort(function (x, y) {
      // true values first
      return x.IsPreferido === y.IsPreferido ? 0 : x.IsPreferido ? -1 : 1;
      // false values first
      // return (x === y)? 0 : x? 1 : -1;
    });

    if (this.state.onlyPreferidos) {
      LabsAux = this.state.AllLabs.filter(item => {
        return item.IsPreferido;
      });
    }

    this.setState({ labs: LabsAux }, this.context.hideLoading);
  }

  showAllLabs() {
    this.setState(
      {
        onlyPreferidos: false,
      },
      this.filterLabs,
    );
  }

  hideAllLabs() {
    this.setState(
      {
        onlyPreferidos: true,
      },
      this.filterLabs,
    );
  }

  acessaMinhaOtica() {
    return this.context.userSession.modulos.includes("Minha Ótica");
  }

  render() {
    return (
      <div className="row mb-5" style={{ width: "70%", margin: "0 auto" }}>
        <div className="col-12 text-center"> 
          <h1 className="x-web-ordering__labs--title">Escolha seu laboratório próprio Essilor.</h1>
          <p className="x-web-ordering__labs--paragraph text-center">
             Escolha um dos seguintes laboratórios próprios da Essilor para realizar seu pedido.
        
            {this.acessaMinhaOtica() && (
              <>
              <br/>
                {/* <strong style={{ color: "red", fontSize: "17px" }}>Novidade!</strong>  */}
                Para configurar seus laboratórios preferenciais,{" "}
                <Link to="/empresa" className="x-web-ordering__intro--link x-web-ordering__intro--subtitle-bold">
                  clique aqui.
                </Link>
              </>
            )}
          </p>
          <p className="x-web-ordering__intro--subtitle2">
          Para fazer seu pedido em outro laboratório, entre em contato diretamente com seu parceiro.
          </p>
        </div>
        <div className="col-10 col-md-12 col-lg-10 mx-auto">
          <div className={"row " + (this.state.labs.length <= 3 ? "text-center d-flex justify-content-center " : "")}>
            {this.state.labs.map((item, index) => {
              var objItem = {
                Id: item.Id,
                IsCor_AR: item.IsCor_AR,
                IsGrowmocao: item.IsGrowmocao,
                IsPreferido: item.IsPreferido,
                cnpj: item.cnpj,
                corteRemoto: item.corteRemoto,
                detailOrder: item.detailOrder,
                email: item.email,
                link: item.link,
                logo: item.logo,
                nome: item.nome,
                promo: item.promo,
                site: item.site,
                telefone: item.telefone,
                link: "/atacado",
                PossuiProgramaFidelidadeProprio_c : item.PossuiProgramaFidelidadeProprio_c
              };
              return (
                <>
                  {item.mensagemCadastro ? (
                    <>
                      <div className="col-12">
                        <Lab
                          key={index}
                          link={item.link}
                          logo={item.logo}
                          labCnpj={item.cnpj}
                          labName={item.nome}
                          laboratorio={objItem}
                          labId={item.Id}
                          AllLabs={this.state.AllLabs}
                          PossuiProgramaFidelidadeProprio_c= {item.PossuiProgramaFidelidadeProprio_c}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 col-sm-6 col-md-4">
                        <Lab
                          key={index}
                          link={item.link}
                          logo={item.logo}
                          labCnpj={item.cnpj}
                          labName={item.nome}
                          laboratorio={objItem}
                          labId={item.Id}
                          AllLabs={this.state.AllLabs}
                          PossuiProgramaFidelidadeProprio_c= {item.PossuiProgramaFidelidadeProprio_c}
                        />
                      </div>
                    </>
                  )}
                </>
              );
            })}
            {this.state.hasPreferidos && !this.state.allPreferidos && (
              <div className="col-12 col-sm-4 col-md-4 text-center d-flex justify-content-center align-items-center ">
                <>
                  {this.state.onlyPreferidos ? (
                    <a className="" rel="noopener noreferrer" onClick={this.showAllLabs}>
                      <img className="" src={botaoLab} alt="Mais laboratórios" />
                    </a>
                  ) : (
                    <a className="" rel="noopener noreferrer" onClick={this.hideAllLabs}>
                      <img className="" src={labsMenos} alt="Apenas preferidos" />
                    </a>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LabChooseAtacado;
