import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../../../appContext";
import Input from "../../../components/inputs/input";
import TextArea from "../../../components/inputs/text-area";
import Select from "../../../components/selects/select";
import CustomButton from "../../../components/buttons/button";

import Frames from "./frames";
import Glasses from "./glasses";

//Import external components
import Alert from "sweetalert2";
import RemoteEdge from "../../../remote-edge/remote-edge";

//Import images

class OrderFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frame: 0,
      imagens: [],
      expanded: false,
      has_fidelity: false,
      isArmacao: false,
      validfile: false,
    };

    this.frameChange = this.frameChange.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this.mostraModelos = this.mostraModelos(this);

    // TK-83-000002 - Retirada interação da medida em diversas armações
    /*this.tipos = {
			'aro_fechado': 0,
			'fio_nylon': 1,
			'tres_pecas': 2
		}*/

    this.medidas = {
      frame_info_arm_vertical: 1,
      frame_info_arm_horizontal: 2,
      frame_info_arm_diametro: 3,
      frame_info_arm_ponte: 4,
    };

    OrderFrame.contextType = AppContext;
  }

  componentDidMount() {
    // criada função "componentDidMount" para ser executada logo após que o HTML da pág. é carregada

    // var fidelidade = false
    // const { order } = this.props

    // const requestOptions = {
    // 	method: 'POST',
    // 	body: JSON.stringify({
    // 		cnpjs: order.laboratorio,
    // 		papel: this.context.userSession.papel,
    // 		conta: this.context.userSession.conta,
    // 	}),
    // }

    // fetch(process.env.REACT_APP_LABS_API + 'get_labs.php', requestOptions)
    // 	.then((response) => {
    // 		return response.json()
    // 	})
    // 	.then((json) => {
    // 		if (json.items) {
    // 			fidelidade = json.items[0].PossuiProgramaFidelidadeProprio_c
    // 		}
    // 		this.setState({ has_fidelity: fidelidade })
    // 	})

    if (this.props.order.remoteEdgePreview) {
      this.props.handleOrderChange({ remoteEdgeDisabled: true });
    }

    this.setState({ cnpjOtica: this.context.userSession.conta }, () => {
      this.loadLentes(); //função assincrona para efetuar integração e recuperar as imagens da API
    });
  }

  loadLentes = async () => {
    const { order } = this.props;
    this.context.showLoading();

    var request = {};
    if (this.props.order.redeOticas && this.props.order.dados_empresa_faturamento_cnpj) {
      request = {
        method: "GET",
        headers: {
          otica: this.props.order.dados_empresa_faturamento_cnpj,
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
        },
      };
    } else {
      request = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
        },
      };
    }

    const urlLentes = process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/modelos-lentes"; //serviço

    await fetch(urlLentes, request)
      .then(result => {
        if (result.status === 200) return result.json();
        else return [];
      })
      .then(json => {
        this.setState({ imagens: json }, () => {
          this.state.imagens.sort(function (a, b) {
            if (parseInt(a.codigo) < parseInt(b.codigo)) {
              return -1;
            }
            if (parseInt(a.codigo) > parseInt(b.codigo)) {
              return 1;
            }
            return 0;
          });
        }); //após o json ser retornado, o mesmo é armazenado em um array no state

        this.context.hideLoading();
      });
  };

  handleClickPrev = () => {
    this.props.onNext(3, "left");
  };

  // TO DO: unificar metodos de checar decimal
  checkDecimal = value => {
    if (value.toString().split(".")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else if (value.toString().split(",")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else {
      return 0;
    }
  };

  handleClickNext = e => {
    e.preventDefault();

    const { order } = this.props;

    var mensagem = "";

    var selectFields = document.getElementsByTagName("SELECT");

    for (var item in selectFields) {
      if (selectFields[item].required && selectFields[item].value === "") {
        mensagem = "Campos obrigatórios pendentes!<br/>";
        selectFields[item].style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var inputFields = document.getElementsByTagName("INPUT");

    for (var item in inputFields) {
      if (inputFields[item].required && inputFields[item].value === "") {
        mensagem = "Campos obrigatórios pendentes!<br/>";
        inputFields[item].style.boxShadow = "0 0 0 1px #f70017";
      }
    }
    var preenchimentoModelo = order.json_parametros.armacao.modelo.preenchimento;

    if (preenchimentoModelo === "OBRIGATORIO" && !order.remoteEdgeDisabled && (order.lenteProntaComMontagem() || !order.isLentePronta())) {
      if (order.frame_modelo) {
        if (order.frame_modelo === undefined || order.frame_modelo === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
        }
      } else {
        mensagem = "Selecione um modelo de armação!";
      }
    }

    var vertical = document.getElementById("frame_info_arm_vertical");

    if (vertical) {
      vertical.style.boxShadow = "0 0 0 1px #B5B5B5";
      if (vertical.value < vertical.min && vertical.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        vertical.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(vertical.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        vertical.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var horizontal = document.getElementById("frame_info_arm_horizontal");
    if (horizontal) {
      horizontal.style.boxShadow = "0 0 0 1px #B5B5B5";
      if (horizontal.value < horizontal.min && horizontal.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        horizontal.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(horizontal.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        horizontal.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var diametro = document.getElementById("frame_info_arm_diametro");
    if (diametro) {
      diametro.style.boxShadow = "0 0 0 1px #B5B5B5";
      if (diametro.value < diametro.min && diametro.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        diametro.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(diametro.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        diametro.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var ponte = document.getElementById("frame_info_arm_ponte");
    if (ponte) {
      ponte.style.boxShadow = "0 0 0 1px #B5B5B5";
      if (ponte.value < ponte.min && ponte.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        ponte.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(ponte.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        ponte.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    if (mensagem !== "") {
      Alert.fire({
        html: mensagem,
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    } else {
      if (localStorage.getItem("info_compl")) {
        localStorage.removeItem("info_compl");
      }
      this.props.onNext(5, "right");
    }
  };

  handleChange = evt => {
    let tamanho  = evt.target.value.length;
    //TK-083-000290 - [Pedidos] Limitar campo Referência da Armação a 15 caracteres
    if((tamanho <= 15 && evt.target.name === 'frame_modelo_v2') || evt.target.name !== 'frame_modelo_v2'){
    this.props.handleOrderChange({ [evt.target.name]: evt.target.value }, () => {
      if (
        !this.props.order.frame_info_arm_vertical &&
        !this.props.order.frame_info_arm_horizontal &&
        !this.props.order.frame_info_arm_diametro &&
        !this.props.order.frame_info_arm_ponte
      ) {
        this.setState({ validfile: false });
      } else {
        this.setState({ validfile: true });
      }
    });
   
 }
  };

  handleSelectChange = evt => {
    // TK-83-000002 - Retirada interação da medida em diversas armações
    /*const len = Object.keys(this.medidas).length
			const frame = (this.tipos[evt.target.value] * len) + 1
			if(!isNaN(frame)){
				this.setState({frame: frame})
			}*/

    this.props.handleOrderChange({
      [evt.target.name]: evt.target.value,
      [evt.target.name + "_value"]: evt.target.options[evt.target.selectedIndex].text,
    });
  };

  _handleChange(evt) {
    let { frametype, value } = evt.target;
    this.setState({
      [frametype]: value,
    });
  }

  onChangeFrame = e => {
    const target = e.currentTarget;

    // TK-83-000035 rfreire - Condicional para permitir o uncheck no radio button do modelo da armação
    if (target.checked) {
      target.checked = false;
      this.props.handleOrderChange({ [e.target.name]: "" });
    } else {
      this.props.handleOrderChange({ [e.target.name]: e.target.value });
    }
  };

  frameChange = evt => {
    const { name } = evt.target;

    // TK-83-000002 - Retirada interação da medida em diversas armações
    //const { frame_tipo_arm } = this.props.order
    //const len = Object.keys(this.medidas).length
    //const frame = (len * this.tipos[frame_tipo_arm]) + this.medidas[name]
    const frame = this.medidas[name];

    this.setState({ frame: isNaN(frame) ? 0 : frame });
  };

  montarTipo = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <div className="x-web-ordering-pedido__frame--item-2">
          <Select
            label="Tipo de armação"
            width="100%"
            name="frame_tipo_arm"
            aria="Selecione a opção de armação"
            itens={order.frame}
            value={order.frame_tipo_arm}
            onChange={this.handleSelectChange}
          />
        </div>
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <div className="x-web-ordering-pedido__frame--item-2">
          <Select
            label="Tipo de armação*"
            width="100%"
            name="frame_tipo_arm"
            aria="Selecione a opção de armação"
            itens={order.frame}
            value={order.frame_tipo_arm}
            onChange={this.handleSelectChange}
            required
          />
        </div>
      );
    }

    return retorno;
  };

  montarVertical = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_vertical"
          label="Vertical"
          width="50%"
          aria="Vertical"
          value={order.frame_info_arm_vertical}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_vertical"
          label="Vertical*"
          width="50%"
          aria="Vertical"
          value={order.frame_info_arm_vertical}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          required={!order.remoteEdgeDisabled}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    return retorno;
  };

  montarHorizontal = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_horizontal"
          label="Horizontal"
          width="50%"
          aria="Horizontal"
          value={order.frame_info_arm_horizontal}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_horizontal"
          label="Horizontal*"
          width="50%"
          aria="Horizontal"
          value={order.frame_info_arm_horizontal}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          required={!order.remoteEdgeDisabled}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    return retorno;
  };

  montarDiagonalMaior = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_diametro"
          label="Diagonal Maior"
          width="50%"
          aria="Diagonal Maior"
          value={order.frame_info_arm_diametro}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_diametro"
          label="Diagonal Maior*"
          width="50%"
          aria="Diagonal Maior"
          value={order.frame_info_arm_diametro}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          required={!order.remoteEdgeDisabled}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    return retorno;
  };

  montarPonte = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_ponte"
          label="Ponte"
          width="50%"
          aria="Ponte"
          value={order.frame_info_arm_ponte}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      if (!this.state.isArmacao) this.setState({ isArmacao: true });
      retorno = (
        <Input
          type="number"
          min="1"
          step="0.1"
          name="frame_info_arm_ponte"
          label="Ponte*"
          width="50%"
          aria="Ponte"
          value={order.frame_info_arm_ponte}
          onChange={this.handleChange}
          onFocus={this.frameChange}
          required={!order.remoteEdgeDisabled}
          readOnly={order.remoteEdgeDisabled}
          disabled={order.remoteEdgeDisabled}
        />
      );
    }

    return retorno;
  };

  montarReferencia = (preenchimento, order) => {
    var retorno = null;
    if (preenchimento === "OPCIONAL") {
      retorno = (
        <div className="x-web-ordering-pedido__frame--item-2">
          <Input
            type="text"
            name="frame_modelo_v2"
            label="Referência da Armação"
            width="100%"
            aria="Referência da Armação"
            tooltip="Marca e referência da armação."
            value={order.frame_modelo_v2}
            onChange={this.handleChange}
          />
        </div>
      );
    }

    if (preenchimento === "OBRIGATORIO") {
      retorno = (
        <div className="x-web-ordering-pedido__frame--item-2">
          <Input
            type="text"
            name="frame_modelo_v2"
            label="Referência da Armação*"
            width="100%"
            aria="Referência da Armação"
            tooltip="Marca e referência da armação."
            value={order.frame_modelo_v2}
            onChange={this.handleChange}
            required
          />
        </div>
      );
    }

    return retorno;
  };

  mostraModelos = () => {
    const { order } = this.props;
    var preenchimento = order.json_parametros.armacao.modelo.preenchimento;

    if (preenchimento === "OPCIONAL" || preenchimento === "OBRIGATORIO") {
      return true;
    }
    return false;
  };

  setRemoteEdge = (text, preview, buttonText) => {
    var remoteEdge = text.replace('"', "\\").replaceAll("\r", "");

    this.props.handleOrderChange({ remoteEdgeDisabled: true });

    document.getElementById("frame_info_arm_vertical").value = "";
    document.getElementById("frame_info_arm_horizontal").value = "";
    document.getElementById("frame_info_arm_ponte").value = "";
    document.getElementById("frame_info_arm_diametro").value = "";

    this.props.handleOrderChange({
      frame_info_arm_vertical: "",
      frame_info_arm_horizontal: "",
      frame_info_arm_ponte: "",
      frame_info_arm_diametro: "",
      frame_modelo: "",
      remoteEdge: remoteEdge,
      remoteEdgePreview: preview,
      remoteEdgeButtonText: buttonText,
    });
  };

  removeRemoteEdge = () => {
    this.setState({
      validfile: false,
    });

    this.props.handleOrderChange({
      remoteEdge: "",
      remoteEdgePreview: null,
      remoteEdgeButtonText: "",
      remoteEdgeDisabled: false,
    });
  };

  render() {
    const { order } = this.props;
    let divRow, frames, more_lens_anchor, less_lens_anchor, arr_tmp, aux, qtd_img, tipo, vertical, horizontal, diagonal_maior, ponte, referencia;

    var toRender;
    divRow = [];
    frames = [];

    this.state.imagens.sort(function (a, b) {
      if (parseInt(a.codigo) < parseInt(b.codigo)) {
        return -1;
      }
      if (parseInt(a.codigo) > parseInt(b.codigo)) {
        return 1;
      }
      return 0;
    });

    this.state.imagens.forEach((element, i) => {
      frames.push(
        <div className="col-sm-3 grid-item text-center">
          <Frames
            frame={(i + 1).toString()}
            imgToRender={element.formato} //base64 da imagem passada como prop ao componente "Frame"
            value={order.frame_modelo}
            onChange={this.handleChange}
            onClick={this.onChangeFrame}
            checked={order.frame_modelo === element.codigo}
            codigo={element.codigo}
          />
        </div>,
      );
    });

    aux = 0;
    arr_tmp = [];
    qtd_img = this.state.imagens.length;
    toRender = qtd_img;
    more_lens_anchor = "";
    less_lens_anchor = "";

    if (qtd_img > 8) {
      if (!this.props.order.expanded) {
        more_lens_anchor = (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <a style={{ color: "#00518b" }} onClick={e => this.props.handleOrderChange({ expanded: true })}>
              Mostrar mais lentes
            </a>
          </div>
        );

        toRender = 8;
      } else {
        less_lens_anchor = (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <a style={{ color: "#00518b" }} onClick={e => this.props.handleOrderChange({ expanded: false })}>
              Mostrar menos lentes
            </a>
          </div>
        );

        toRender = qtd_img;
      }
    }

    while (aux < toRender) {
      for (let j = 0; j < 4; j++) {
        arr_tmp[aux] = frames[aux];
        aux++;
      }
      divRow.push(<div className="row mt-4">{arr_tmp}</div>); // push no array de DIV para a cada div ser armazenada 4 imagens a cada iteração
      arr_tmp = [];
    }

    tipo = this.montarTipo(order.json_parametros.armacao.tipo.preenchimento, order);
    vertical = this.montarVertical(order.json_parametros.armacao.vertical.preenchimento, order);
    horizontal = this.montarHorizontal(order.json_parametros.armacao.horizontal.preenchimento, order);
    diagonal_maior = this.montarDiagonalMaior(order.json_parametros.armacao.diametroMaior.preenchimento, order);
    ponte = this.montarPonte(order.json_parametros.armacao.ponte.preenchimento, order);
    referencia = this.montarReferencia("OPCIONAL", order);

    return (
      <section className={`x-web-ordering-pedido__frame ${this.props.order.direction === "right" ? "right" : "left"} content`}>
        <div className="order-main-container">
          {(order.quantidade === 1 || order.quantidade === "1") && (
            <>
              <div className="x-web-ordering-pedido__frame--body ">
                <div className="">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="x-web-ordering-pedido__frame--body">
                        <div className="x-web-ordering-pedido__frame--intro intro__form--container">
                          <h2 className="x-web-ordering-pedido__frame--title intro__form--title">Informações da armação</h2>
                          <p className="order-paragraph right">*Campos obrigatórios</p>
                        </div>
                        {this.state.isArmacao && (
                          <>
                            {tipo}

                            <div className="x-web-ordering-pedido__frame--item-2">
                              {horizontal}
                              {vertical}
                            </div>

                            <div className="x-web-ordering-pedido__frame--item-2">
                              {ponte}
                              {diagonal_maior}
                            </div>
                          </>
                        )}
                        {referencia}
                        {this.props.order.hasCorteRemoto && (
                          <div className="mb-5 pb-5">
                            <RemoteEdge
                              setRemoteEdge={this.setRemoteEdge}
                              removeRemoteEdge={this.removeRemoteEdge}
                              preview={order.remoteEdgePreview}
                              buttonText={order.remoteEdgeButtonText}
                              validfile={this.state.validfile}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 pt-5">{this.state.isArmacao && <Glasses glasses={this.state.frame} />}</div>
                  </div>
                </div>
              </div>

              {this.mostraModelos && !order.remoteEdgeDisabled && (
                <div>
                  <div className="x-web-ordering-pedido__frame--intro intro__form--container space">
                    <h2 className="x-web-ordering-pedido__frame--title intro__form--title">Modelo da armação</h2>
                  </div>

                  <div>
                    <p className=" intro__form--subtitle-sm">Selecione o modelo que mais se assemelha com a armação</p>
                    {divRow}
                    {toRender <= 8 ? more_lens_anchor : less_lens_anchor}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="x-web-ordering-pedido__frame--intro intro__form--container space">
                <h2 className="x-web-ordering-pedido__frame--title intro__form--title">Dados adicionais</h2>
              </div>

              <div className="x-web-ordering-pedido__frame--column">
                <div className="x-web-ordering-pedido__frame--item-2">
                  <TextArea
                    name="descricao"
                    label="Observação"
                    width="100%"
                    aria="Observação"
                    tooltip="Observações gerais não especificadas no formulário."
                    value={order.descricao}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              {/* <div className="x-web-ordering-pedido__frame--column">
									{this.state.has_fidelity ? (
										<div className="x-web-ordering-pedido__frame--item-2">
											<Input
												type='number'
												name="codigoParticipante"
												label="Código do Participante"
												width='100%'
												aria="Código do Participante"
												tooltip="Informe o seu número do programa de fidelidade do laboratório (caso participe)."
												value={order.codigoParticipante}
												onChange={this.handleChange}
											/>
										</div>
									) : (
										''
									)}
								</div> */}
            </div>
          </div>
          <div className="order-buttons">
            <div className="order-prev-button">
              <CustomButton label="Anterior" width="11.125rem" icon_arrow_left onClick={this.handleClickPrev.bind(this)} />
            </div>

            <div className="order-next-button">
              <CustomButton label="Validar pedido" width="11.125rem" icon_arrow_right orange_button onClick={this.handleClickNext.bind(this)} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OrderFrame;
