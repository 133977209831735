import React, { Component } from "react";


//Import CSS
import "./menu-horizontal.css";

class MenuHorizontal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_option: 1,
    };
  }
  handleChangeOption(option) {
    if (option !== this.state.selected_option) {
      this.setState({
        selected_option: option,
      });
      if(this.props.warningFather == true){
        this.props.warningFatherFunction(option);
      }
    }
  }
  getOptionsSelected = () =>{
    return this.state.selected_option;
  }
  render() {
    const divStyle = {
      margin: this.props.margin || '50px 3px 50px;', 
    };
    const divStyleOptions ={
      width:this.props.width 
    }
    return (
    <div className="MenuHorizontal_container">
      <div className="MenuHorizontal_menu-options" style={divStyleOptions}>
        <div className="MenuHorizontal_menu-itens" style={divStyle}>

          {this.props.menuOptions.map((option) =>  (
            <button key={option.id} className={this.state.selected_option === option.id ? "selected" : ""} onClick={() => this.handleChangeOption(option.id)}>
            {option.label}
          </button>
            
          ))}
          
          
        </div>
      </div>
      <div className="MenuHorizontal_selected-page">
         {this.props.menuOptions.filter(option => option.id === this.state.selected_option).map(option => option.component)}
      </div>
    </div>
    );
  }
}

export default MenuHorizontal;